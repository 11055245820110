// import BackgroundScrollLock from "./_background-scroll-lock";
// import Utilities from "./_utilities";
/* UI制御 */
export default class CommonUI {
    constructor() {
        this.currentX = window.scrollX; // Xスクロール位置
        this.currentY = window.scrollY; // Yスクロール位置
        this.currentWindowWidth = window.innerWidth; // ウィンドウ幅
        // this.scrollLock = BackgroundScrollLock.getInstance();
        this.mainHeader = document.querySelector('.l-header'); // メインヘッダー
        this.navToggle = document.querySelector('.l-header__navtoggle'); // ナビトグルボタン
        this.navWrappers = document.querySelectorAll('.l-nav'); // メインナビ（カテゴリーナビ含む）
        this.globalNav = document.querySelectorAll('.l-nav__lv1 > li:has(.l-nav__dropmenu) > .l-nav__lv1__cattop > button'); // グローバルナビリンク
        // this.categoryNav = document.querySelectorAll('.l-mainnav__category > li:has(.l-mainnav__sub) > .l-mainnav__category__top > a'); // カテゴリーナビリンク
        this.navClose = document.querySelector('.l-nav-wrapper__footer__close'); // PCメガメニュー閉じるボタン
        this.dropmenuClose = document.querySelectorAll('.l-nav__dropmenu__close'); // PCメガメニュー閉じるボタン
        // this.closeLayer = document.querySelector('.l-mainheader__layer'); // 半透明レイヤー
        if (this.mainHeader && this.navWrappers.length > 0) {
            this.activateFanc();
            this.navEvents();
            // 画面リサイズでナビリセット
            window.addEventListener('resize', () => {
                if (this.currentWindowWidth !== window.innerWidth) {
                    clearTimeout(this.resizeTimer);
                    this.resizeTimer = setTimeout(() => {
                        this.navEvents();
                        this.currentWindowWidth = window.innerWidth;
                    }, 200);
                }
            });
        }
    }
    activateFanc() {
        this.mainFunc = {
            // UI閉じる（共通）
            uiClose: (ev) => {
                // this.scrollLock.unlock();
                // SPナビ閉じる
                this.mainHeader.classList.remove('is-spnav-active');
                // メガメニュー閉じる
                document.querySelectorAll('.l-nav__lv1 > li').forEach((elm) => {
                    elm.classList.remove('is-pcdropmenu-active');
                });
                // this.mainHeader.classList.remove('pc-megamenu-active');
            },
            // メインナビトグル（SP）
            navToggleHandler: (ev) => {
                ev.preventDefault();
                if (this.mainHeader.classList.contains('is-spnav-active')) {
                    this.mainFunc.uiClose();
                }
                else {
                    this.mainFunc.uiClose();
                    // this.scrollLock.lock();
                    this.mainHeader.classList.add('is-spnav-active');
                }
            },
            // グローバルナビ開閉（SP）
            globalNavListToggle: (ev) => {
                const trigger = ev.currentTarget;
                const parent = trigger.closest('.l-mainnav__global > li').classList.toggle('sp-global-active');
                ev.preventDefault();
            },
            // カテゴリーナビ開閉（SP）
            catNavListToggle: (ev) => {
                const trigger = ev.currentTarget;
                const parent = trigger.closest('.l-mainnav__category > li').classList.toggle('sp-cat-active');
                ev.preventDefault();
            },
            // メガメニュートグル（PC）
            megaMenuToggle: (ev) => {
                const target = ev.currentTarget;
                const parentLi = target.closest('.l-nav__lv1 > li');
                ev.preventDefault();
                if (!parentLi.classList.contains('is-pcdropmenu-active')) {
                    this.mainFunc.uiClose();
                    // this.mainHeader.classList.add('is-pcdropmenu-active');
                    setTimeout(() => {
                        parentLi.classList.add('is-pcdropmenu-active');
                    }, 200);
                }
                else {
                    this.mainFunc.uiClose();
                }
            }
        };
    }
    // ナビ用イベントバインド
    navEvents() {
        this.destroy(); // reset
        if (window.innerWidth < 750) {
            // SPナビアクティブ
            // メインナビ
            this.navToggle.addEventListener('click', this.mainFunc.navToggleHandler);
            // グローバルナビ
            // this.globalNav.forEach( elm => {
            // 	elm.addEventListener('click', this.mainFunc.globalNavListToggle); // グローバルナビ開閉
            // });
            // カテゴリーナビ
            // this.categoryNav.forEach( elm => {
            // 	elm.addEventListener('click', this.mainFunc.catNavListToggle); // カテゴリーナビ開閉
            // });
            // ナビ閉じるボタン
            this.navClose.addEventListener('click', this.mainFunc.navToggleHandler);
        }
        else {
            // PCナビアクティブ
            this.globalNav.forEach(elm => {
                // メガメニュー開く
                elm.addEventListener('click', this.mainFunc.megaMenuToggle);
            });
            // メガメニュー閉じる
            this.dropmenuClose.forEach(elm => {
                elm.addEventListener('click', (ev) => {
                    this.mainFunc.uiClose();
                });
            });
            // レイヤーで閉じる
            // this.closeLayer.addEventListener('click', this.mainFunc.uiClose);
        }
    }
    // ナビ用イベント解除
    destroy() {
        // 全部閉じる
        this.mainFunc.uiClose();
        // SP
        this.navToggle.removeEventListener('click', this.mainFunc.navToggleHandler);
        // this.globalNav.forEach( elm => {
        // 	elm.removeEventListener('click', this.mainFunc.globalNavListToggle);
        // });
        // this.categoryNav.forEach( elm => {
        // 	elm.removeEventListener('click', this.mainFunc.catNavListToggle);
        // });
        this.navClose.removeEventListener('click', this.mainFunc.navToggleHandler);
        // PC
        // this.categoryNav.forEach( elm => {
        // 	elm.removeEventListener('click', this.mainFunc.megaMenuToggle);
        // });
        this.dropmenuClose.forEach(elm => {
            elm.removeEventListener('click', this.mainFunc.uiClose);
        });
        // this.closeLayer.removeEventListener('click', this.mainFunc.uiClose);
        // 背景ロック解除
        // this.scrollLock.unlock();
    }
}
